import React from "react";
import "./style.css";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Typewriter from "typewriter-effect";
import { introdata, meta, services } from "../../content_option";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { Possibility } from "../../components/possibility";
import { Feature } from "../../components/feature";
import { Features } from "../../components/features";
import { What } from "../../components/what";
import { Customer } from "../../components/customer";
import { ContactUs } from "../../components/contact";
import { Footer } from "../../components/footer";
import logo from '../../assets/images/logo.png';

import backgroundImage from "../../assets/images/engine.jpg";


export const Home = () => {
  return (
    <HelmetProvider>
      <section id="home" className="home">
        <Helmet>
          <meta charSet="utf-8" />
          <title> {meta.title}</title>
          <meta name="description" content={meta.description} />
        </Helmet>
        <div className="intro_sec d-block d-lg-flex align-items-center ">
          <div
            className="h_bg-image order-1 order-lg-2 h-100 "
            style={{ backgroundImage: `url(${backgroundImage})` }}
          ></div>
          <div className="text order-2 order-lg-1 h-100 d-lg-flex justify-content-center">
            <div className="align-self-center ">
              <div className="intro mx-auto">
                <h2 className="mb-1x">{introdata.title}</h2>
                <h1 className="mb-1x">
                  <Typewriter
                    options={{
                      strings: [
                        introdata.animated.first,
                        introdata.animated.second,
                        introdata.animated.third,
                        introdata.animated.fourth
                      ],
                      autoStart: true,
                      loop: true,
                      deleteSpeed: 10,
                    }}
                  />
                </h1>
                {/* <p className="mb-1x">{introdata.description}</p> */}
                <p className="mb-1x text-justify">
                  Technozor stands as your trusted partner, empowering you to unlock the full potential of data-driven insights and AI technologies, enabling transformative growth, informed decision-making, and unparalleled
                  market success.
                 </p>
                    
                <p className="mb-1x text-justify">

                  Founded in 2021, Technozor SARL brings together a team of data enthusiasts, engineers, and scientists. Our mission is to offer Enterprise AI solutions that address the world's most pressing business problems,
                  rapidly transforming the organizational landscape.
                </p>

                <p className="mb-1x text-justify">
                  Leveraging our expertise, we enable you to build a perfect data analytics ecosystem, incorporating anomaly detection, computer vision, and Natural Language
                  Processing. By harnessing these capabilities, Technozor empowers you with actionable insights and a competitive advantage in the new AI era.
                </p>

                <div className="intro_btn-action pb-5">
                  <Link to="/service" className="text_2">
                    <div id="button_p" className="ac_btn btn ">
                      Our services
                      <div className="ring one"></div>
                      <div className="ring two"></div>
                      <div className="ring three"></div>
                    </div>
                  </Link>

                  <Link to="/contact">
                    <div id="button_h" className="ac_btn btn">
                      Contact Us
                      <div className="ring one"></div>
                      <div className="ring two"></div>
                      <div className="ring three"></div>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="what">
        <Container className="align-items-center" style={{ paddingTop: 10 }}>
          <What />
          <Possibility className="sec_sp" />
        </Container>
      </section>

      <section id="services" className="services p-5">
        <Container className="intro_sec align-items-center">
          {/* services */}
          <Row className="mb-1 mt-3">
            <Col lg="8">
              <h1 className="display-4 mb-4">Services</h1>
              <hr className="t_border my-4 ml-0 text-left" />
            </Col>
          </Row>
          <Row>
            <Col lang="5">
              <p className="color_sec mt-2 text-justify">
                Our services are customized to address specific business challenges based on your organization's maturity in people,
                 process, and technology. With expertise in data-driven insights and advanced technologies, we help you streamline 
                 workflows, drive tangible outcomes, and unlock your organization's full potential.
              </p>
            </Col>
          </Row>
          <Row>
            <Col lg="3"></Col>
            <Col lg="9">
                {services.map((data, i) => {
                  return (
                    <div className="s py-4" key={i}>
                      <h5 className="service__title">{data.title}</h5>
                      <p className="service_desc">{data.description}</p>
                    </div>
                  );
                })}
              </Col>
          </Row>
        </Container>
      </section >

      <section id="features" className="p-3">
        <Container>

          <div className="what-heading">
             <Row className="mb-1 mt-3">
                <Col lg="4">
                  <h1 className="display-4 mb-4">The potential of Advanced Analytics</h1>
                </Col>
                <Col lg="8">
                  <p className="text-justify">
                    At Technozor, we are dedicated to accelerating your organization's success in the dynamic AI era. Our tailored solutions empower businesses to overcome critical challenges, drive operational excellence, enhance customer experiences, and unlock untapped growth 
                    opportunities. With advanced technologies and deep domain expertise, we help you stay ahead of the competition, optimize processes, leverage data-driven insights, and make informed decisions. 
                  </p>
                </Col>
          </Row>
            
            
          </div>

          <div className="what-container">

            <Feature title="Fraud detection" text="Safeguard your organization against threats with our advanced anomaly detection capabilities. We offer industry-specific fraud detection solutions for sectors such as banking, insurance, government, and healthcare." />,
            <Feature title="Computer vision" text="Streamline document processing, minimize errors, and enhance operational efficiency with our cutting-edge computer vision pipelines. Drive cost savings and ensure accuracy and consistency." />,
            <Feature title="Natural language processing" text="Revolutionize customer care and automate workflows with our advanced NLP solutions. Seamlessly engage with customers across digital channels using the latest natural language understanding advancements." />,
            <Feature title="Graph analytics" text="Maximize organizational knowledge and efficiency with our powerful graph analytics solutions. Identify patterns, uncover insights, and optimize processes for better decision-making and improved business efficiency." />,
            <Feature title="Mathematical optimization" text="Achieve operational excellence by leveraging mathematical optimization techniques. Optimize resources, minimize costs, and make informed decisions to achieve your business objectives." />,
            <Feature title="Statistical modeling" text="Unlock the power of data with our statistical analysis expertise. Make data-driven decisions, gain deeper insights, and improve performance to drive growth." />,
            <Feature title="Research & development" text="Stay ahead of the curve with access to our global network of scientists and professionals. Collaborate on cutting-edge R&D projects to gain a competitive edge and foster innovation." />
            
          </div>
        </Container>
      </section>

      <section>
        <Container className="align-items-center">
          {/* Features */}
          <Features className="sec_sp" />
        </Container>
      </section>

      <section id="possibility">
        <Container className="align-items-center">
          {/* Possibility */}

        </Container>
      </section>

      <section id="customer" className="customer">
        <Customer />
      </section>

      <section id="contactUs" className="contactUs">
        <ContactUs />
      </section>



      <section id="footer" className="footer">
        <Footer />
      </section>
    </HelmetProvider >
  );
};
