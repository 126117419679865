import React from 'react';
import './feature.css'

export const Feature = ({ title, text }) => {
  return (
    <div className="features-container__feature">
      <div className="features-container__feature-title">
        <h1>{title}</h1>
        <div />
      </div>
      <div className="features-container_feature-text">
        <p className="text-justify">{text}</p>
      </div>
    </div>
  )
};