import React from 'react'
import { Feature } from '../feature'
import './what.css'

export const What = () => {
  return (
    <div className="what section__margin" id="transformer">
      <div className="what-feature">
        <Feature title="ABOUT Technozor AI" text="Technozor AI aims to rethink and optimize organisation using analytics toolset to assess organization functions. The goals is to help our customer augement their value proposition by designing an end to end data strategy covering process, people and technologies." />
      </div>
    </div>
  )
}
