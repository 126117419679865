import React from 'react';
import { Feature } from '../feature';
import './features.css';

const featuresData = [
   {
      title: 'Predict business outcomes',
      text: 'AI & ML techniques helps organization to predict potentiel outcomes and simulate vareity of business scenario.'
   },

   {
      title: 'Personalization of services',
      text: 'By monitoring customer behavior, AI provides tremendous insight for business owners to adopt to the market changes  and customize their service as per the current demand.'
   },

   {
      title: 'Imporve strategy making process',
      text: 'The powerful combination of business expertise and ML drives better strategic revenue growth decisions.'
   },

   {
      title: 'Gain competitve edge',
      text: 'ML helps businesses gain a competitive advantage, especially for those already with data analytics capabilities.'
   }
]

export const Features = () => {
   return (
      <div className="features" id="features">
         <div className="features-heading p-3">
            <h1 className="gradient__text">
               The adoption of data analytics empower your organization with.
            </h1>
         </div>

         <div className="features-container">
            {featuresData.map((item, index) => (
               <Feature title={item.title} text={item.text} key={item.title + index}></Feature>
            ))}

         </div>
      </div>
   )
};
