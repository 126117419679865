import React from 'react';
import possibilityImage from '../../assets/images/possibility.png';
import './possibility.css'

export const Possibility = () => {
   return (

      <div className="possibility section__padding" id="possibility">
         <div className="posssibility-image">
            <figure>
               <img src={possibilityImage} alt="possobility" />
            </figure>
         </div>
         <div className="posssibility-content">
            <h1 className="gradient__text">The possibilities are beyond your imagination</h1>
            <p className="text-justify">
               In the last five years, the field of AI has made major progress in almost all its standard sub-areas, including
                vision, speech recognition and generation, natural language processing (understanding and generation), 
                image and video generation, multi-agent systems, planning, decision-making, and integration of vision and motor
                 control for robotics. In addition, breakthrough applications emerged in a variety of domains including games, 
                 medical diagnosis, logistics systems, autonomous driving, language translation, and interactive personal assistance. 
                 The sections that follow provide examples of many salient developments.

            </p>
         </div>
      </div>
   )
};
